import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import Main from "./components/Main";
import Claim from "./components/Claim";
import {ThemeProvider, createTheme} from '@mui/material';
import { UserProvider } from './contexts/userContext';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Press Start 2P"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const Page404 = () => <h1>Four:oh:four</h1>

function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route index element={<Main /> } />
              <Route path="/claim" element={<Claim  />} />
              <Route element={Page404}></Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
