import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import {getEllipsisTxt} from '../formatters';
import Blockie from '../Blockie';
// import {Theme} from '@mui/material/styles';
import AccountDialog from './AccountDialog';
import ConnectWalletDialog from './ConnectWalletDialog';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useDomain } from '../../hooks/useDomain';
import { UserContext } from '../../contexts/userContext';

function Account() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);

  const user = React.useContext(UserContext);
  const {domain, fetchDomain} = useDomain(user.moralis);

  useEffect(() => {
    if(user.acct) {
      fetchDomain(user.acct);
    }
  }, [user.acct]);

  if (!user.isAuth || !user.acct) {
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          size="large"
          onClick={() => setAuthModalOpen(true)}
          sx={{
            height: 36,
            textTransform: 'none',
            fontSize: 13,
            py: 2.25,
            px: 1,
            borderRadius: 0,
            color: '#00b700',
            borderColor: '#00b700',
            '&:hover': {
              color: '#90EE90',
              borderColor: '#90EE90',
            },
          }}
        >
          Connect Wallet
        </Button>
        <ConnectWalletDialog open={authModalOpen} setOpen={setAuthModalOpen} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        size="large"
        onClick={() => setModalOpen(true)}
        sx={{
          height: 36,
          textTransform: 'none',
          py: 2.25,
          px: 1.5,
          borderRadius: 0,
          borderColor: '#90EE90',
          backgroundColor: '',
          fontSize: 12,
          color: '#90EE90',
          '&:hover': {
            color: '#e22929',
            borderColor: '#e22929',
          },
        }}
        endIcon={<Blockie currentWallet scale={3} />}
      >
        {domain ?? getEllipsisTxt(user.acct, 5)}
      </Button>
      <AccountDialog open={modalOpen} setOpen={setModalOpen} />
    </React.Fragment>
  );
}

export default Account;
