import {useEffect, useState} from 'react';
import {useMoralis} from 'react-moralis';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import {SxProps, Theme} from '@mui/material/styles';

import Blockie from '../Blockie';
import {getEllipsisTxt} from '../formatters';
import Check from './Check';

import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext'

interface AddressProps {
  copyable?: boolean;
  avatar?: 'left' | 'right';
  size?: number;
  address?: string;
  sx?: SxProps<Theme>;
}

export default function Address(props: AddressProps) {
  const user = useContext(UserContext);
  const [address, setAddress] = useState<string | false>();
  const [clicked, setClicked] = useState(false);

  const {copyable, avatar, size, sx = []} = props;

  useEffect(() => {
    setAddress(props.address || (user.isAuth && user.acct!));
  }, [user.acct, user.isAuth, props]);

  if (!address) {
    return (
      <Skeleton animation="wave" width="100%">
        <Typography>.</Typography>
      </Skeleton>
    );
  }

  const Copy = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#1780FF"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{cursor: 'pointer'}}
      onClick={() => {
        navigator.clipboard.writeText(address);
        setClicked(true);
      }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M15 3v4a1 1 0 0 0 1 1h4" />
      <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
      <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
      <title id="copy-address">Copy Address</title>
    </svg>
  );

  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          height: 36,
          gap: 0.625,
          borderRadius: 9,
          bgColor: 'rgba(255, 255, 255, 0.1)',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {avatar === 'left' && <Blockie address={address} size={7} />}
      <Typography variant="body1">
        {size ? getEllipsisTxt(address, size) : address}
      </Typography>
      {avatar === 'right' && <Blockie address={address} size={7} />}
      {copyable && (clicked ? <Check /> : <Copy />)}
    </Box>
  );
}
