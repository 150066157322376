import {useState} from 'react';
import {useMoralis} from 'react-moralis';
import contractAbi from '../utils/contract.json';
// import testContractAbi from '../utils/testcontract.json';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS

export const useMint = (Moralis: any) => {
  // const {Moralis} = useMoralis();
  const [isMinting, setIsMinting] = useState(false);

  async function executeTransaction(opts: any) {
    const defaultOpts = {
      contractAddress: CONTRACT_ADDRESS,
      abi: contractAbi
    }

    if(!CONTRACT_ADDRESS) {
      console.log("ERROR: Missing REACT_APP_CONTRACT_ADDRESS env variable");
      return "";
    }

    const mergedOpts = {...defaultOpts, ...opts};
    const tx = await Moralis.executeFunction(mergedOpts);
    return tx;
  }

  async function publicMint(qty: number) {

    const price = (Moralis.Units.ETH("0.025"));

    const options = {
      functionName: 'mint',
      msgValue: Moralis.web3Library.BigNumber.from(price).mul(qty).toString(),
      params: {
        quantity: qty
      }
    }

    const tx = await executeTransaction(options);

    await waitForTransaction(tx);
  }

  async function presaleMint(qty: number, signature: string) {
    const sig = Moralis.web3Library.utils.splitSignature(signature);

    const price = (Moralis.Units.ETH("0.04"));

    const options = {
      functionName: 'presaleMint',
      msgValue: Moralis.web3Library.BigNumber.from(price).mul(qty).toString(),
      params: {
        quantity: qty,
        _r: sig.r,
        _s: sig.s,
        _v: sig.v
      }
    }

    const tx = await executeTransaction(options);
    await waitForTransaction(tx);
  }

  async function waitForTransaction(tx: any) {
    setIsMinting(true);

    await tx.wait();

    setIsMinting(false);
  }

  return {publicMint, presaleMint, isMinting};
};
