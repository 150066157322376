import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
// import {useMoralis} from 'react-moralis';
import {getExplorer} from '../networks';

import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import { useDomain } from '../../hooks/useDomain';

import Address from '../Address/Address';

interface AccountDialogProps extends DialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AccountDialog(props: AccountDialogProps) {
  // const {account, chainId, logout} = useMoralis();
  const user = useContext(UserContext);
  // const { setDomain } = useDomain(user.moralis);
  const {open, setOpen} = props;

  return (
    <Dialog className="showcase" open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          color: 'black',
          background: '#00b700',
          borderColor: '#00b700',
          borderWidth: 2,
        }}
      >
        Account
      </DialogTitle>
      <DialogContent
        sx={{p: 3, background: 'black', borderColor: '#00b700', borderWidth: 1}}
      >
        <Card sx={{mt: 3, borderRadius: 0}}>
          <CardContent
            sx={{
              p: 2,
              borderRadius: 0,
              color: '#00b700',
              background: 'black',
              borderColor: '#00b700',
              borderWidth: 1,
            }}
          >
            <Address avatar="left" size={6} copyable sx={{fontSize: '20px'}} />
          </CardContent>
          <CardActions
            sx={{background: 'black', borderColor: '#00b700', borderWidth: 1}}
          >
            <Button
              href={`${getExplorer(user.chain!)}address/${user.acct}`}
              target="_blank"
              rel="noreferrer"
              startIcon={<LaunchOutlinedIcon />}
              sx={{textTransform: 'none', borderRadius: 2}}
            >
              View on Explorer
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
      <DialogActions
        sx={{p: 3, background: 'black', borderColor: '#00b700', borderWidth: 1}}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={async () => {
            await user.logOut();
            window.localStorage.removeItem('connectorId');
            setOpen(false);
            // setDomain(null);
          }}
          sx={{
            width: '100%',
            borderRadius: 0,
            textTransform: 'none',
            color: '#00b700',
            borderColor: '#00b700',
            '&:hover': {
              color: '#e22929',
              borderColor: '#e22929',
            },
          }}
        >
          Disconnect Wallet
        </Button>
      </DialogActions>
    </Dialog>
  );
}
