import nerdz from '../../media/NerdzOG.png'
import coin from '../../media/CoinFlipAnimation.gif'
import roadmap from '../../media/Roadmap.png'
import icon from '../../media/icon.png'
// import '../App.css';

function Roadmap() {
  return (
    <div className="Pink-wrapper">
      <div className="Container">
        <div className='rgrid'>
          <div className="r1">
            <img className="roadmap r1" src={roadmap} alt="" />
          </div>
          <div className="r2 floating">
            <img src={icon} alt="" />
          </div>
          <div className="r3 floating">
            <img src={icon} alt="" />
          </div>
        </div>
        <div className="grid">
          <img className="box" src={nerdz} alt="" />
          <div className="box2">
            <h2>Collection Info</h2>
            <p>
              <br />
              - Total Supply: 4,444 Nerdz.
              <br />
              <br />
              - Whitelist [3/19]: 0.04 ETH per Nerd, 4 Nerdz per wallet.
              <br />
              <br />
              - Public Sale [3/20]: 0.05 ETH per Nerd, 8 Nerdz per wallet.
              <br />
              <br />
              - 15% of mint proceeds will be allocated to a community wallet.
              <br />
              <br />
              - Votes will be held by community to direct funds.
              <br />

            </p>
          </div>
          <div className='box1'>
            <div className="terminal">
             <p>Each Nerd will be randomly generated from 5-10 properties, with over 165 traits. Properties with a value of "None" will not be included as a trait. The art pays homage to some timeless video games, anime, television, movies, and nerdy fashion styles, ideas, etc... there is definitely a Nerd out there for everyone.
               </p>
            </div>
          </div>
          <img  className="box4 glow" src={coin} alt=""  />
          <div className='box5'>
            <h2>ERC-20 TOKEN: $IQ</h2>
            <p>Utility Token for the CryptoNerdz universe.
            <br />
            <br />
              - Nerdz holders start earning $IQ once collection mints out.
            <br />
            <br />
              - $IQ's utility will come in the form of:
            <br />
            </p>
            <ul className="list">
                <li>
                  Whitelist spots for partnered projects.
                </li>
                <li>
                  NFT Raffles with partner projects.
                </li>
                <li>
                  Purchase of IRL Nerdz merch.
                </li>
                <li>
                  More to come...
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
