import contractAbi from './contract.json';
// import testContractAbi from './testcontract.json'

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

export default async function fetchContractValue(fn: string, Moralis: any) {

  if (!CONTRACT_ADDRESS) {
    console.log('ERROR: Missing REACT_APP_CONTRACT_ADDRESS env variable');
    return '';
  }

  const options = {
    contractAddress: CONTRACT_ADDRESS,
    functionName: fn,
    abi: contractAbi,
  };

  const message = await Moralis.executeFunction(options);
  // console.log(fn + ' returned ' + message);
  return message;
}
