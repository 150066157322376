import '../App.css';
import './Claim/Claim.css';

// import Account from './Account/Account';
import ClaimBar from './Claim/ClaimBar';
import ClaimTop from './Claim/ClaimTop';
// import NFTBalance from './Claim/NFTBalance';

function Claim() {
    return (
        <div className='c-background scrollcontainer'>
            <ClaimBar />
            <ClaimTop />
        </div>
    )
}

export default Claim;
