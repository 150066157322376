import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useTotalSupply = (Moralis: any) => {
  const [totalSupply, setTotalSupply] = useState(0);

  function fetchTotalSupply() {
    fetchContractValue('totalSupply', Moralis).then(value => {
      const result = Number(value);
      setTotalSupply(result);
    });
  }
  return {totalSupply, fetchTotalSupply};
};
