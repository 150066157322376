import {useState} from 'react';

export const usePresaleSig = (Moralis: any) => {
  const [presaleSig, setPresaleSig] = useState(null);

  async function fetchSig(address: string) {
    const response = await fetch(`./api/verify/${address}`);
    const sig = await response.json();
    setPresaleSig(sig['signature']);
  }
  return {presaleSig, fetchSig};
};
