import coin from '../../media/CoinFlipAnimation.gif'
import NFTBalance from './NFTBalance';

function ClaimTop() {
    return (
        <div className='c-container'>
            <img className='glow' src={coin} alt="" />
            <h2 className='c-font2'>Claim $IQ Token</h2>
            <NFTBalance />
        </div>
    );
}

export default ClaimTop;
