import {DialogContentText} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {connectors} from './config';

import { UserContext } from '../../contexts/userContext';
import { useContext } from 'react';

interface ConnectWalletDialogProps extends DialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
  ? parseInt(process.env.REACT_APP_CHAIN_ID)
  : 4;

export default function ConnectWalletDialog(props: ConnectWalletDialogProps) {
  const user = useContext(UserContext);
  const {open, setOpen} = props;

  return (
    <Dialog className="showcase" open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1.25,
          fontFamily: 'Press Start 2P',
          fontSize: 20,
          letterSpacing: 0,
          color: 'black',
          background: '#00b700',
          borderColor: '#00b700',
          borderWidth: 2,
        }}
      >
        Connect Wallet
      </DialogTitle>
      <DialogContent
        sx={{
          p: 1.875,
          background: 'black',
          borderColor: '#00b700',
          borderWidth: 2,
        }}
      >
        <Grid container>
          {connectors.map(({title, icon, connectorId}) => (
            <Grid item xs={6} key={title}>
              <Button
                onClick={async () => {
                  try {
                    await user.auth({
                      provider: connectorId,
                      chainId: CHAIN_ID,
                    });
                    window.localStorage.setItem(
                      'connectorId',
                      connectorId || 'injected'
                    );
                    setOpen(false);
                  } catch (e) {
                    console.error(e);
                  }
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: 'auto',
                  mx: 'auto',
                  py: 2,
                  px: 1,
                  my: 2,
                  textTransform: 'none',
                  color: '#00b700',
                }}
              >
                <Box
                  component="img"
                  src={icon}
                  alt={title}
                  sx={{
                    alignSelf: 'center',
                    fill: 'rgb(40, 13, 95)',
                    flexShrink: 0,
                    mb: 1,
                    height: 30,
                  }}
                />
                <DialogContentText
                  sx={{fontSize: 10, fontWeight: 500, color: '#00b700'}}
                >
                  {title}
                </DialogContentText>
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
