import DJ from "../../media/Nerd_DJFID.png";
import Rains from "../../media/Nerd_Rains.png";
import Yeti from "../../media/Nerd_YETI.png";
import Otto from "../../media/Otto.png";
import ninja from "../../media/Nerd_NINJAI.png";
import Jack from "../../media/Jack.png";
import Leo from "../../media/Nerd_Leo.png";

interface Team {
  name: any;
  description: any;
  picture: any;
  twitter: any;
}
const TeamCard = (data: Team) => {
  return (
    <div
      className="m-4 flex flex-1
    2xl:min-w-[350px]
    2xl:max-w-[400px]
    sm:min-w-[270px]
    sm:max-w-[300px]
    min-w-full
    flex-col p-3"
    >
      <div className="flex flex-col items-center w-full my-3">
        <a href={data.twitter} target="_blank">
          <div className="flex align-center items-center justify-center">
            <img src={data.picture} alt="pfp" className="w-56 TeamPic" />
          </div>
        </a>
        <br />
        <h1 className="Font text-base text-md mt-5"> {data.name}</h1>
        <p className="Font text-[10px] text-base mt-1"> {data.description}</p>
      </div>
    </div>
  );
};

function Team() {
  return (
    <div className="Lilac-wrapper" id="Team">
      <div className="Container flex flex-col md:px-5 px-4 mt-5">
          <div className="Team">
            <h2>CryptoNerdz Team</h2>
            <p>click pictures for socials!</p>
          </div>
          <div className="flex flex-wrap justify-center text-center items-center">
          <TeamCard
              name="Rains"
              description="Co-Founder"
              twitter="https://twitter.com/r_rainss"
              picture={Rains}
            />
            <TeamCard
              name="Otto"
              description="Co-Founder"
              twitter="https://twitter.com/OttoVonklaus"
              picture={Otto}
            />
            <TeamCard
              name="DJFID"
              description="Artist"
              twitter="https://twitter.com/djfid_nfts"
              picture={DJ}
            />
            <TeamCard
              name="SwetiYeti"
              description="Developer"
              twitter="https://twitter.com/swetiyeti_eth"
              picture={Yeti}
            />
            <TeamCard
              name="DopeAssNinja"
              description="Website Developer"
              twitter="https://twitter.com/_Dope_Ass_Ninja"
              picture={ninja}
            />
            <TeamCard
              name="Jack"
              description="Advisor"
              twitter="https://twitter.com/JackStickCandle"
              picture={Jack}
            />
            <TeamCard
              name="Leo"
              description="Alpha"
              twitter="https://twitter.com/leonidas2610"
              picture={Leo}
            />
          </div>
      </div>
    </div>
  );
}

export default Team;
