import contractAbi from '../utils/token.json';
import { useWeb3ExecuteFunction } from 'react-moralis';

const IQ_ADDRESS = process.env.REACT_APP_IQ_ADDRESS;

export const useClaim = (Moralis: any) => {
  const contractProcessor = useWeb3ExecuteFunction();

  async function claimIQ() {
    if (!IQ_ADDRESS) {
      console.error('ERROR: Missing REACT_APP_IQ_ADDRESS env variable');
      return;
    }

    const options = {
      contractAddress: IQ_ADDRESS,
      abi: contractAbi,
      functionName: 'claimReward',
      chainId: Moralis.chainId,
    };

    await contractProcessor.fetch({
      params: options,
      onError: (error: any) => {
            console.log(error);
      },
    });
  }

  return {claimIQ};
};
