import MintNFT from '../../components/Contracts/MintNFT';
import Counter from '../Contracts/Counter';
import one from '../../media/1of1.png';
import logo from '../../media/logo.png';
import song from '../../audio/CN_Vibez.mp3';
import monitor from '../../media/MintTerminal.png';
import {useState, useRef} from 'react';

function Apptop() {
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(new Audio(song));

  const play = () => {
    setPlaying(true);
    audioRef.current.play();
  };

  const pause = () => {
    setPlaying(false);
    audioRef.current.pause();
  };

  return (
    <div className="App-top" id="Home">
      <div className="Container">
        <img src={logo} alt="" />
        <div className="grid">
          <img className='monitor' src={monitor} alt="" />
          <div className='supply'>
            <Counter />
          </div>
          <div className='mint'>
            <MintNFT />
          </div>
        </div>
        <div className='align-music'>
          <div className="justify-center text-center text-xl">
              <button className="music" onClick={playing ? pause : play}>{ playing ? <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAbElEQVRIie3Nuw2AMAyE4T+swWMfYI/sxCRhn2SP0FAFTGUkhO7K88kfKH9PMC87K5UN6M+mUIksJI+9DScyMDRtZmby2HcmfH0CMHrtn+BXI1iwYMGCBQv+Nlxuuuy1t+FKbJ5lAtFtr/w+B62LHVF5OgYFAAAAAElFTkSuQmCC"/> : <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABPElEQVRIie3WQSsEUQDA8d9sLg6c5OQ7+AwOspZy26/grKw4cFGyRZKTg3wBDkoWOa5ypThxUE5SaleI7DhoawnN7OyOi/9tXvPm13u9mYb//rSSYyVn9o21iwh+gMOGqxMUDCunDX8U2pUxIeuyFXAm8p2BUaELJesO9CaFo6/4cw9YVlWU95QmXO8G86o25L3FgaNv9ff1YV2XUyW5OBOTrvhrRwKTsk7ThqGGbTUFI67ThOs9CqypWZBTSROudye05MGKvJf6YNLDFaUegUVdDhsH04A/Cj7vbhrwndC0isHGwY42gr8ernbAkV6nVsORPyCtgs8xZdhe1AlJD9cNxlX1x0FpfsX3QkWdVg14buYBceFXbMqYNeS2GTAuHGJLaEbOVRIwDtyWn72f4DK6BeZk7bQS/C/13gEA4F0gkn0OxQAAAABJRU5ErkJggg=="/>}</button>
          </div>
          <div className="music-info justify-center align-center text-center">
              <h3>MINTING MUSIC</h3>
              <p>SONG - CN Vibez | ARTIST - DJFID</p>
              <p></p>
          </div>
        </div>
        <img className="justify-center one" src={one} alt="" />
      </div>
    </div>
  );
}

export default Apptop;
