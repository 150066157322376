import React, { createContext, useEffect, useReducer } from 'react';
import { useMoralis } from 'react-moralis';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const { Moralis, account, isAuthenticated, authenticate, chainId, logout} = useMoralis();

    const user = {
        moralis: Moralis,
        acct: account,
        isAuth: isAuthenticated,
        auth: authenticate,
        chain: chainId,
        logOut: logout
      }

      return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
      );
}
