import Marquee from 'react-fast-marquee';
import img1 from '../../media/1.png';
import img2 from '../../media/2.png';
import img3 from '../../media/3.png';
import img4 from '../../media/4.png';
import img5 from '../../media/11.jpeg';
import img6 from '../../media/6.png';
import img7 from '../../media/7.png';
import img8 from '../../media/8.png';
import img9 from '../../media/9.png';
import img10 from '../../media/Nerd_Velma.png';
import img11 from '../../media/5.png';

function NerdMarquee() {

return (
<Marquee className="mymarquee" gradient={false} direction="left" gradientWidth="0" speed={50}>
    <div id="Roadmap" className="inline-flex justify-between">
        <img className="h-64 w-64" src={img1} />
        <img className="h-64 w-64" src={img2} />
        <img className="h-64 w-64" src={img3} />
        <img className="h-64 w-64" src={img4} />
        <img className="h-64 w-64" src={img5} />
        <img className="h-64 w-64" src={img6} />
        <img className="h-64 w-64" src={img7} />
        <img className="h-64 w-64" src={img8} />
        <img className="h-64 w-64" src={img9} />
        <img className="h-64 w-64" src={img10} />
        <img className="h-64 w-64" src={img11} />
    </div>
</Marquee>
);
}

export default NerdMarquee;
