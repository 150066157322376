import {useTotalSupply} from '../../hooks/useTotalSupply';
import { useEffect } from 'react';
import { UserContext } from '../../contexts/userContext'
import { useContext } from 'react';

function Counter() {
  const user = useContext(UserContext);
  const {totalSupply, fetchTotalSupply} = useTotalSupply(user.moralis);

  useEffect(() => {
    if(user.acct){
      fetchTotalSupply();
    }
  }, [user.acct])

  if (!user.isAuth || !user.acct) {
    return <div className=""></div>;
  } else {
    return <div className="App-count glow">{totalSupply} <br /> <p>of</p> 4444</div>;
  }

}

export default Counter;
