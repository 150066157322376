import {useState} from 'react';
import IQAbi from '../utils/token.json';

const IQ_CONTRACT = process.env.REACT_APP_IQ_ADDRESS;

export const useIQUnclaimed = (Moralis: any) => {
  const [totalUnclaimed, setTotalUnclaimed] = useState(0);

  async function fetchTotalClaimable(address: string) {
    const options = {
      contractAddress: IQ_CONTRACT!,
      functionName: 'getTotalClaimable',
      abi: IQAbi,
      chainId: Moralis.chainId,
      params: {user: address},
    };

    Moralis.executeFunction(options).then((result) => {
        setTotalUnclaimed(result);
    }).catch((err) => {
        console.error("Something Happened: ", err)
    });
  }

  return {
    totalUnclaimed,
    setTotalUnclaimed,
    fetchTotalClaimable
  };
};

