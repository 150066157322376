import {useState} from 'react';
import {useMoralis} from 'react-moralis';
import contractAbi from '../utils/contract.json';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS

export const mintCt = (Moralis: any) => {
  // const {Moralis, account} = useMoralis();
  const [mintCount, setMintCt] = useState(0);

  async function executeTransaction(opts: any) {
    const defaultOpts = {
      contractAddress: CONTRACT_ADDRESS,
      abi: contractAbi
    }

    if(!CONTRACT_ADDRESS) {
      console.log("ERROR: Missing REACT_APP_CONTRACT_ADDRESS env variable");
      return "";
    }

    const mergedOpts = {...defaultOpts, ...opts};
    const tx = await Moralis.executeFunction(mergedOpts);
    return tx;
  }

  function getMintCount() {
    const options = {
      functionName: 'numberMinted',
      params: {
        owner: Moralis.account
      }
    }

    executeTransaction(options).then(value=> {
      setMintCt(+value);
    });
  }

  return {mintCount, getMintCount};
};
