import {useState, useEffect} from "react";
// import {useMoralis, useApiContract} from "react-moralis";
import {useSaleState} from "../../hooks/useSaleState";
import {useMintMax} from '../../hooks/useMintMax';
import {useTotalSupply} from "../../hooks/useTotalSupply";
import {useMint} from "../../hooks/useMint";
import {mintCt} from "../../hooks/mintCt";
import {usePresaleSig} from "../../hooks/usePresaleSig";

import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext'


import '../../App.css';


function MintNFT() {
  const user = useContext(UserContext);

  const {presaleActive, publicSaleActive, fetchSaleStatus} = useSaleState(user.moralis);
  const {presaleMintMax, publicSaleMintMax, fetchMintMax} = useMintMax(user.moralis);
  const {publicMint, presaleMint, isMinting} = useMint(user.moralis);
  const {presaleSig, fetchSig} = usePresaleSig(user.moralis);
  const {mintCount, getMintCount} = mintCt(user.moralis);

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if(user.acct){
      fetchMintMax("public");
      fetchMintMax("presale");
      fetchSaleStatus("public");
      fetchSaleStatus("presale");
    }
  }, [user.acct])

  function increment() {
    if(presaleActive && !publicSaleActive) {
      if(quantity < 4) {
        setQuantity(quantity+1);
      }
    }
    else if(publicSaleActive) {
      if(quantity < 8) {
        setQuantity(quantity+1);
      }
    }
  }

  function decrement() {
    if (quantity > 1) {
      setQuantity(quantity-1);
    }
  }

  function mintNFTs() {
      publicMint(quantity);
  }

  if (!user.isAuth || !user.acct) {
    return (
      <div className="MintStatus green">Please Connect Wallet</div>
    );
  }

  if(publicSaleActive || (presaleActive && (presaleSig !== ""))) {
    if(isMinting) {
      return (<div className="text-white text-base MintStatus">Please wait, minting NFTs...</div>);
    }

    else {
      return (
      <div className="text-white text-base increment">
        <div className="v1">
          <button className="incrementbut" onClick={() => decrement()}>-</button>
            <div className="v2">{quantity}</div>
          <button className="incrementbut" onClick={() => increment()}>+</button>
        </div>
          <button className="MintButton" onClick={() => mintNFTs()}>Mint</button>
      </div>
      );
    }
  }

  else {
   return (<div className="text-white text-base MintStatus red">Sale has not started...</div>);
  }
}

export default MintNFT;
