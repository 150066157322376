import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useMintMax = (Moralis: any) => {
  const [presaleMintMax, setPresaleMintMax] = useState(0);
  const [publicSaleMintMax, setPublicSaleMintMax] = useState(0);

  function fetchMintMax(type: string) {
    if (type === 'presale') {
      fetchContractValue('maxPresaleMint', Moralis).then(value => {
        const result = Number(value);
        setPresaleMintMax(result);
      });
    } else if (type === 'public') {
      fetchContractValue('maxPublicMint', Moralis).then(value => {
        const result = Number(value);
        setPublicSaleMintMax(result);
      });
    }
  }

  return {presaleMintMax, publicSaleMintMax, fetchMintMax};
};
