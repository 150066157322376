import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useSaleState = (Moralis: any) => {
  const [presaleActive, setPresaleActive] = useState(false);
  const [publicSaleActive, setPublicSaleActive] = useState(false);

  function fetchSaleStatus(type: string) {
    if (type === 'presale') {
      fetchContractValue('presaleActive', Moralis).then(value => {
        setPresaleActive(Boolean(value));
      });
    } else if (type === 'public') {
      fetchContractValue('publicSaleActive', Moralis).then(value => {
        setPublicSaleActive(Boolean(value));
      });
    }
  }

  return {presaleActive, publicSaleActive, fetchSaleStatus};
};
