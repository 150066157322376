import Account from '../Account/Account';
import discord from '../../media/gdiscord.png';
import twitter from '../../media/gtwitter.png';
import opensea from '../../media/gopensea.png';
import { Link } from 'react-router-dom';
// import '../App.css';

function ClaimBar() {
  return (
    <div className="App-header justify-between border-b-2">
      <ul className="App-social">
        <li>
          <a
            href="https://discord.gg/EfnNg5x7yJ"
            target="_blank"
            rel="noreferrer"
          >
            <img className="" src={discord} alt="Discord" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/Thecryptonerdz"
            target="_blank"
            rel="noreferrer"
          >
            <img className="" src={twitter} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://opensea.io/collection/thecryptonerdz"
            target="_blank"
            rel="noreferrer"
          >
            <img className="" src={opensea} alt="OpenSea" />
          </a>
        </li>
      </ul>
      <div className="links">
        <Link to="/">HOME</Link>
      </div>
      <Account />
    </div>
  );
}

export default ClaimBar;
