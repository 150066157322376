import '../App.css';
import Mint from './Main/Mint';
import Navbar from './Main/Navbar';
import Roadmap from './Main/Roadmap';
import NerdMarquee from './Main/Marquee';
import Team from './Main/Team';
import AppFooter from './Main/Appfooter';

function Main() {
    return (
    <div className='background scrollcontainer'>
        <Navbar />
        <Mint />
        <NerdMarquee />
        <Roadmap />
        <Team />
        <AppFooter />
    </div>
    )
}

export default Main;
