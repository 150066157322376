import Account from '../../components/Account/Account';
import discord from '../../media/gdiscord.png';
import twitter from '../../media/gtwitter.png';
import opensea from '../../media/gopensea.png';
import { Link } from 'react-router-dom';
// import '../App.css';

function Navbar() {
  return (
    <div className="App-header justify-between border-b-2">
      <ul className="App-social">
        <li>
          <a
            href="https://discord.gg/EfnNg5x7yJ"
            target="_blank"
            rel="noreferrer"
          >
            <img className="" src={discord} alt="Discord" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/Thecryptonerdz"
            target="_blank"
            rel="noreferrer"
          >
            <img className="" src={twitter} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://opensea.io/collection/thecryptonerdz"
            target="_blank"
            rel="noreferrer"
          >
            <img className="" src={opensea} alt="OpenSea" />
          </a>
        </li>
      </ul>
      <div className="links">
        <a
          href="#Home"
          className="hover:text-green-300 ease-in-out duration-300"
        >
          HOME
        </a>
        &nbsp;|&nbsp;
        <a
          href="#Roadmap"
          className="hover:text-green-300 ease-in-out duration-300"
        >
          ROADMAP
        </a>
        &nbsp;|&nbsp;
        <a
          href="#Team"
          className="hover:text-green-300 ease-in-out duration-300"
        >
          TEAM
        </a>
        &nbsp;|&nbsp;
        <Link to="/claim">$IQ</Link>
      </div>
      <Account />
    </div>
  );
}

export default Navbar;
